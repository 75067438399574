import React from "react";
function Socials() {
	return (
		<div className="socials">
			<a href="https://discord.com/oauth2/authorize?client_id=660818351638970370&permissions=8&scope=applications.commands%20bot">
				Invite
			</a>{" "}
			| <a href="https://twitter.com/CorynthBot">Twitter</a> |{" "}
			<a href="https://github.com/cxllm/corynth">Source Code</a> |{" "}
			<a href="https://discord.gg/MddmTkjsmg">Support Server</a> |{" "}
			<a href="https://top.gg/bot/660818351638970370">Top.gg</a>
		</div>
	);
}

export default Socials;
